<template>
  <div>
    <v-card color="transparent" flat>
      <div class="font-weight-bold text-center mb-5 title">
        To help you get started, we have prepared a series of instructional videos that
        will guide you through the different features and functionalities of
        <span class="primary--text">MEALZEE</span>.
      </div>
      <div class="font-weight-bold text-center mb-5 subtitle-1">
        Simply click on the button below to see the how-to guide of your choice.
      </div>
      <v-card class="mx-md-10 mx-lg-15" flat>
        <v-card-text class="px-md-5 px-lg-15">
          <div class="d-flex flex-row justify-center align-center flex-wrap">
            <div
              class="col-12 col-md-6 col-lg-6 px-md-5 px-lg-15"
              v-for="(btn, i) in confBtns"
              :key="i"
            >
              <v-btn
                @click="selectedSupport(i)"
                style="width: 100%"
                rounded
                color="primary"
                dark
              >
                {{ btn.title }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="1120px">
      <close-button @click="dialog = false" overflow />
      <v-card>
        <v-card-text class="px-0 py-lg-15">
          <div
            class="d-flex flex-row flex-wrap justify-center align-center"
            style="overflow-y: scroll; max-height: 620px"
          >
            <div
              class="col-12 col-md-8 col-lg-8"
              style="position: relative; display: block; width: 100%"
            >
              <div
                v-html="support?.video"
                class="box-shadow"
                style="
                  position: relative;
                  display: block;
                  width: 100%;
                  min-height: 320px;
                  max-height: 500px;
                  height: 100%;
                "
              />
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div v-html="support?.html" />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Support',
  metaInfo: {
    title: 'Support',
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    confBtns () {
      return this.user.role == 'personal_trainer' ? this.buttons1 : this.buttons
    }
  },
  data() {
    return {
      buttons: [
       {
          title: 'Recipes Tab',
          video:
            '<iframe src="https://player.vimeo.com/video/882885282?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Recipes" data-ready="true" style="position:absolute; top:0; left: 0; width: 100%; height: 100%"></iframe>',
          html: '<h1>Recipes Tab</h1><br/><p>Learn how to navigate the recipes tab and add new recipes to MEALZEE. This tab includes the following subtabs:</p><ul><li>All Recipes: Explore community recipes added by nutrition professionals. </li><li>My Community Recipes: Discover your own community recipes that can generate a passive income. </li><li>My Local Recipes: These recipes are exclusive to your account.</li><li>Pending: These recipes have been submitted for review. </li><li>Requested: Learn how to approve recipe customizations and increase your revenue. </li><li>Saved: Find out how to save recipes for future use.</li></ul>',
        },
        {
          title: 'Adding a new Recipe',
          video: '<iframe src="https://player.vimeo.com/video/882882086?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Adding A New Recipe" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Adding a New Recipe</h1><br/> <p>You can add your recipes to the MEALZEE Community for everyone to benefit from your expertise. Each time your recipes are used in a meal plan or eBook, you earn money. #passiveincome </p>',
        },
        {
          title: 'Logo & Brand Colour',
          video: '<iframe src="https://player.vimeo.com/video/882885140?h=a06ea68927" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Adding A New Recipe" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Logo & Brand Colour:</h1><br/> <p>Add your own logo and brand colour to ensure all meal plans and eBooks are branded as your own.</p>',
        },
        {
          title: 'Generate Recipes',
          video: '<iframe src="https://player.vimeo.com/video/882884450?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%2" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Adding A New Recipe" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Generate Recipes:</h1><br/><p>Your clients can do this for you by completing the meal plan questionnaire, or you can do this yourself from within MEALZEE.</p>',
        },
        {
          title: 'Editing a Meal Plan',
          video: '<iframe src="https://player.vimeo.com/video/882883493?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Editing Meal Plans" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Editing a Meal Plan</h1><br/> <p>Edit the Meal Plan to suit your desired nutrition targets and meal patterns. You can drag & drop recipes for fast editing, lock and unlock recipes to make fast changes, or search the recipe database to find what you need. By clicking on the ‘VIEW RECIPE CARD’ for each recipe, you can also customise any recipe within MEALZEE.</p>',
        },
        {
          title: 'Downloading a Meal Plan',
          video: '<iframe src="https://player.vimeo.com/video/882882979?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Downloading Meal Plan" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Downloading a Meal Plan</h1><br/> <p>Before downloading, you can hide the nutrition information from the meal plan and recipe cards. Once ready, you can then download a meal plan, shopping list and recipe cards.</p>',
        },
        {
          title: 'Folders, Move & Duplicate',
          video: '<iframe src="https://player.vimeo.com/video/883122303?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Folders Move and Duplicate" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Folders, Move & Duplicate: </h1> <br/> <p>Meal plans are created underneath a folder system. Recipes can then be counted each time they are used in that folder (or for that client). Meal plans can then be duplicated or moved to other folders or within their existing folder.</p>',
        },
        {
          title: 'Recipe Ebook',
          video: '<iframe src="https://player.vimeo.com/video/882882482?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Creating a Recipe eBook" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: "<h1>Recipe Ebook</h1><br/> <p>If you just want a recipe eBook with recipe suggestions targeted to your client's preferences, rather than a scripted meal plan, then the Recipe eBook is the perfect option.</p>",
        },
        {
          title: 'Food Database',
          video: '<iframe src="https://player.vimeo.com/video/882885716?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - The Food Database" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Food Database:</h1><br/> <p>This is where you can add your own food to the MEALZEE database. The information you provide will be verified before the food is published.</p>',
        },
        {
          title: 'The Subscription tab',
          video: '<iframe src="https://player.vimeo.com/video/882885610?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Subscriptions" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Subscription Tab:</h1><br/><p>Explore the subscription tab and choose the most suitable payment option for your needs—weekly or annual payments available. Save upwards of 30-40% by paying annually. Also join the MEALZEE for Nutrition & Fitness Professionals Facebook Group to secure special offers that can save you upwards of 60-70%.</p>',
        },
        {
          title: 'The profile tab',
          video: '<iframe src="https://player.vimeo.com/video/882885223?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - Profile" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Profile Tab:</h1><br/><p>Discover how to update your details, change units of energy, and add your business social media handles and website to be hyperlinked on the generated meal plans.</p>',
        },
        {
          title: 'The Marketplace',
          video: '<iframe src="https://player.vimeo.com/video/882885853?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Nutrition Professionals - The Marketplace" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Marketplace:</h1><br/> <p>Learn how to import ready-made meal plans created by other users to save time in creating new plans.</p>',
        },
        {
          title: 'Promo Recipe eBook & Client List',
          video: '<iframe src="https://player.vimeo.com/video/956833351?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Promo Recipe eBook & Client List" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Promo Recipe eBook & Client List:</h1>',
        },
      ],
      buttons1: [
        {
          title: 'Recipes Tab',
          video:
            '<iframe src="https://player.vimeo.com/video/883170317?h=37fce24f99" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for health and fitness Professional - Recipes" data-ready="true" style="position:absolute; top:0; left: 0; width: 100%; height: 100%"></iframe>',
          html: '<h1>Recipes Tab</h1><br/><p>Learn how to navigate the recipes tab and add new recipes to MEALZEE. This tab includes the following subtabs:</p><ul><li>All Recipes: Explore community recipes added by nutrition professionals. </li><li>My Community Recipes: Discover your own community recipes that can generate a passive income. </li><li>My Local Recipes: These recipes are exclusive to your account.</li><li>Pending: These recipes have been submitted for review. </li><li>Requested: Learn how to approve recipe customizations and increase your revenue. </li><li>Saved: Find out how to save recipes for future use.</li></ul>',
        },
        {
          title: 'Adding a new Recipe',
          video: '<iframe src="https://player.vimeo.com/video/883169884?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Adding A New Recipe" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Adding a New Recipe</h1><br/> <p>You can add your recipes to your local MEALZEE account. If you have nutrition qualifications, you could add your recipes to the MEALZEE community and start earning a passive income from your recipes. Contact us via email if this is of interest to you. hello@mealzee.com</p>',
        },
        {
          title: 'Logo & Brand Colour',
          video: '<iframe src="https://player.vimeo.com/video/882885140?h=a06ea68927" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Logo and Brand Colour" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Logo & Brand Colour:</h1><br/> <p>Add your own logo and brand colour to ensure all meal plans and eBooks are branded as your own.</p>',
        },
        {
          title: 'Generate Recipes',
          video: '<iframe src="https://player.vimeo.com/video/883170019?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Generate Recipes" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Generate Recipes:</h1><br/><p>Your clients can do this for you by completing the meal plan questionnaire, or you can do this yourself from within MEALZEE.</p>',
        },
        {
          title: 'Editing a Meal Plan',
          video: '<iframe src="https://player.vimeo.com/video/883169714?h=e3c307293e" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Editing Meal Plans" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Editing a Meal Plan</h1><br/> <p>Edit the Meal Plan to suit your desired nutrition targets and meal patterns. You can drag & drop recipes for fast editing, lock and unlock recipes to make fast changes, or search the recipe database to find what you need. By clicking on the ‘VIEW RECIPE CARD’ for each recipe, you can also customise any recipe within MEALZEE.</p>',
        },
        {
          title: 'Downloading a Meal Plan',
          video: '<iframe src="https://player.vimeo.com/video/883169679?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Downloading Meal Plan" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Downloading a Meal Plan</h1><br/> <p>Before downloading, you can hide the nutrition information from the meal plan and recipe cards. Once ready, you can then download a meal plan, shopping list and recipe cards.</p>',
        },
        {
          title: 'Folders, Move & Duplicate',
          video: '<iframe src="https://player.vimeo.com/video/883198532?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Folders Move and Duplicate" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Folders, Move & Duplicate: </h1> <br/> <p>Meal plans are created underneath a folder system. Recipes can then be counted each time they are used in that folder (or for that client). Meal plans can then be duplicated or moved to other folders or within their existing folder.</p>',
        },
        {
          title: 'Recipe Ebook',
          video: '<iframe src="https://player.vimeo.com/video/883170251?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Creating a Recipe eBook" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: "<h1>Recipe Ebook</h1><br/> <p>If you just want a recipe eBook with recipe suggestions targeted to your client's preferences, rather than a scripted meal plan, then the Recipe eBook is the perfect option.</p>",
        },
        {
          title: 'The Marketplace',
          video: '<iframe src="https://player.vimeo.com/video/883170477?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - The Marketplace" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Marketplace</h1><br/> <p>Learn how to import ready-made meal plans created by other users to save time in creating new plans.</p>',
        },
        {
          title: 'The Subscription tab',
          video: '<iframe src="https://player.vimeo.com/video/883170433?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Subscriptions" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Subscription Tab:</h1><br/><p>Explore the subscription tab and choose the most suitable payment option for your needs—weekly or annual payments available. Save upwards of 30-40% by paying annually. Also join the MEALZEE for Nutrition & Fitness Professionals Facebook Group to secure special offers that can save you upwards of 60-70%.</p>',
        },
        {
          title: 'The Profile tab',
          video: '<iframe src="https://player.vimeo.com/video/883170217?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="MEALZEE for Health and Fitness Professionals - Profile Tab" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>The Profile Tab:</h1><br/><p>Discover how to update your details, change units of energy, and add your business social media handles and website to be hyperlinked on the generated meal plans.</p>',
        },
        {
          title: 'Promo Recipe eBook & Client List',
          video: '<iframe src="https://player.vimeo.com/video/956833351?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Promo Recipe eBook & Client List" data-ready="true" style="position:absolute; top:0; left: 0; width: 100; height: 100%"></iframe>',
          html: '<h1>Promo Recipe eBook & Client List</h1>',
        },
      ],
      dialog: false,
      support: null,
    };
  },

  methods: {
    selectedSupport(i) {
      this.dialog = true;
      this.support = this.confBtns[i];
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}
</style>
